
/*
Template Name: Adminto Dashboard
Author: CoderThemes
Email: coderthemes$gmail.com
File: Menu
*/

.topbar {

  left: 0px;
  position: fixed;
  right: 0;
  top: 0px;
  // z-index: 999;
  z-index: 1010;

  .topbar-left {
    background: $custom;
    float: left;
    text-align: center;
    height: 71px;
    position: relative;
    width: 250px;
    z-index: 1;

    .logo {
      line-height: 70px;
    }
  }
}

.navbar-default {
  background-color: $custom;
  border-radius: 0px;
  border: none;
  margin-bottom: 0px;
  padding: 0px 10px;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  .navbar-left {
    li {
      padding-right: 10px;
    }
  }

  .button-menu-mobile {
    display: none;
  }

  .nav-link {
    padding: 0;
    line-height: 70px;
    color: rgba($dark,0.8);
  }

  .dropdown-toggle {
    &:after {
      content: initial;
    }
  }

  .menu-left {
    overflow: hidden;
  }

  .topbar-right-menu {
    li {
      float: left;
    }
  }
}

.logo {
  color: $white !important;
  font-size: 32px;
  font-family: 'Rancho', cursive;

  i {
    display: none;
  }

  span {
    span {
      color: $white;
    }
  }
}

.user-box {
  text-align: center;
  padding: 30px 0px 20px 0px;

  .user-img {
    position: relative;
    height: 88px;
    width: 88px;
    margin: 0px auto;

  }

  h5{
    a{
      color: $muted;
    }
  }

  .user-status {
    height: 12px;
    width: 12px;
    position: absolute;
    bottom: 7px;
    right: 15px;

    i {
      font-size: 15px;
    }
  }
  .user-status.away {
    i {
      color: $warning;
    }
  }
  .user-status.offline {
    i {
      color: $danger;
    }
  }
  .user-status.online {
    i {
      color: $success;
    }
  }
  .user-status.busy {
    i {
      color: $muted;
    }
  }

  ul {
    li {
      a{
        color: $muted;

        &:hover {
          color: $custom;
        }
      }
    }
  }
}

.notification-box {

  ul {
    max-height: 60px;
    li {
      a {
        font-size: 26px;
        color: $white;
        display: block;
        line-height: 70px;
      }
    }
  }

  .noti-dot {
    position: relative;
    top: -45px;
  }

  .pulse {
    width: 2px;
    height: 2px;
    border: 3px solid $danger;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: $danger;
    z-index: 10;
    position: absolute;
    right: 6px;
  }

  .dot {
    border: 4px solid $danger;
    background: transparent;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    height: 30px;
    width: 30px;
    -webkit-animation: pulse 3s ease-out;
    -moz-animation: pulse 3s ease-out;
    animation: pulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -12px;
    left: 2px;
    z-index: 1;
    opacity: 0;
  }

  @-moz-keyframes pulse {
    0% {
      -moz-transform: scale(0);
      opacity: 0.0;
    }
    25% {
      -moz-transform: scale(0);
      opacity: 0.1;
    }
    50% {
      -moz-transform: scale(0.1);
      opacity: 0.3;
    }
    75% {
      -moz-transform: scale(0.5);
      opacity: 0.5;
    }
    100% {
      -moz-transform: scale(1);
      opacity: 0.0;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(0);
      opacity: 0.0;
    }
    25% {
      -webkit-transform: scale(0);
      opacity: 0.1;
    }
    50% {
      -webkit-transform: scale(0.1);
      opacity: 0.3;
    }
    75% {
      -webkit-transform: scale(0.5);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0.0;
    }
  }
}

// Swal Popup

.swal2-popup{
  background-color: rgb(47, 62, 71) !important;
}

.swal2-title {
  color: rgb(153, 153, 153) !important;
  font-size: 22px !important;
}

.swal2-content{
  color: rgb(153, 153, 153) !important;
}

.swal2-styled.swal2-confirm{
  background-color: #e64942 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  padding: 10px 24px !important;
}

.swal2-styled.swal2-cancel{
  color: #555 !important;
  background-color: #efefef !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  padding: 10px 24px !important;
}


.side-menu {
  top: 70px;
  width: 250px;
  z-index: 10;
  background: $bg-leftbar;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);

  .waves-effect {
    .waves-ripple {
      background-color: rgba($custom, 0.3);
    }
  }
}

// Multiselect

.multiNgSelect{
  .ng-select {
      &.ng-select-focused {
          .ng-select-container {
              box-shadow: none!important;
          }
      }
      .ng-select-container {
          height: unset !important;
          min-height: 48px!important;
          width: 100%!important;
          border: none!important;
          padding: 0px 20px!important;
          border-radius: 12px!important;
          background: transparent !important;
          &:hover {
              box-shadow: none!important;
          }
          &:focus {
              box-shadow: none!important;
          }
          .ng-value-container {
              padding-left: 0px !important;
              align-items: center;
              gap: 12px;
              .ng-placeholder {
                  width: 100%;
                  text-align: right;
              }
              .ng-value {
                  width: unset !important;
                  height: 30px;
                  .itemValue,
                  .itemLabel {
                      width: 50%;
                  }
                  .itemLabel {
                      text-align: right;
                  }
              }
          }
          .ng-arrow-wrapper {
              padding-left: 12px;
              padding-right: 0;
              .ng-arrow:before {
                  display: contents;
                  position: relative;
                  font-family: "FontAwesome";
                  content: "\f107";
                  color: #177CDA;
                  font-weight: 900;
                  font-size: 24px;
                  background-image: none;
              }
              .ng-arrow {
                  display: contents;
              }
          }
      }
      &.ng-select-opened>.ng-select-container .ng-arrow {
          top: 0!important;
          border-width: 0!important;
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
      }
      &.ng-select-multiple .ng-select-container .ng-value-container{
          .ng-value{
              background-color: #177CDA !important;
              padding: 4px 6px !important;
              color: #fff;
              .ng-value-icon.left{
                  border-right: 1px solid #415663 !important;
                  margin-right: 6px;
                  &:hover{
                      background-color: transparent;
                  }
              }
              .ng-value-icon{
                  display: inline-block;
                  cursor: pointer;
                  padding: 1px 5px;
                  &:hover{
                      background-color: transparent;
                  }
              }
          }
      } 
          .ng-placeholder{
              top: 11px !important;
              right: 41px !important;
          }
  }
}

.content-page {
  margin-left: 250px;
  overflow: hidden;

  .content {
    padding: 0 10px;
    margin-top: 93px;
  }
}


.button-menu-mobile {
  background: transparent;
  border: none;
  color: rgba($white,0.7);
  font-size: 21px;
  line-height: 68px;

  &:hover {
    color: $white;
  }
}

.sidebar-inner {
  height: $height;
}

#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

#sidebar-menu {
  width: 100%;

  a {
    line-height: 1.3;
  }

  ul {
    li {
      .menu-arrow {
        -webkit-transition: -webkit-transform .15s;
        -o-transition: -o-transform .15s;
        transition: transform .15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: 'Material-Design-Iconic-Font';
        text-rendering: auto;
        line-height: 18px;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);

        &:before {
          content: '\f2fb';
        }
      }
      a.subdrop .menu-arrow {
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    ul {
      // display: none;
      li {
        border-top: 0;
      }
      li.active {
        a {
          color: $custom;
        }
      }
      a {
        color: darken($muted,10%);
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        border-left: 3px solid transparent;
        display: block;
        padding: 10px 20px 10px 65px;
        &:hover {
          color: $custom;
        }
        i {
          margin-right: 5px;
        }
      }
      ul {
        a {
          padding-left: 80px;
        }
      }
    }
  }
  .label {
    margin-top: 2px;
  }
  .subdrop {
    border-left: 3px solid $custom;
    color: $custom !important;
  }
}


#sidebar-menu > ul > li > a {
  color: lighten($dark,35%);
  display: block;
  padding: 12px 20px;
  margin: 4px 0px;
  background-color: $bg-leftbar;
  border-left: 3px solid transparent;

  &:hover {
    color: $custom;
    text-decoration: none;
  }
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu {
  ul {
    li {
      a{
        i{
          display: inline-block;
          font-size: 16px;
          line-height: 17px;
          margin-left: 3px;
          margin-right: 15px;
          text-align: center;
          vertical-align: middle;
          width: 20px;
        }
        i.md {
          font-size: 18px;
        }
        .drop-arrow {
          float: right;

          i{
            margin-right: 0px;
          }
        }
      }
    }
  }
}


#sidebar-menu > ul > li > a.active {
  border-left: 3px solid $custom;
  color: $custom !important;
}


.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
}
#sidebar-menu ul ul {
  display: none;
}

#wrapper.enlarged {
  .menu-title ,.menu-arrow{
    display: none !important;
  }

  .user-box {
    .user-img {
      height: 48px;
      width: 48px;
    }
    h5 {
      display: none;
    }
  }

  #sidebar-menu {
    ul {
      ul {
        border: 2px solid #f4f8fb;
        margin-top: -5px;
        padding-top: 5px;
        z-index: 9999;
        background-color: $white;
      }
    }
  }
  .left.side-menu {
    width: 70px;
    z-index: 5;

    #sidebar-menu > ul > li > a {
      padding: 15px 20px;
      &:hover {
        background: $lightdark !important;
      }
      &:active {
        background: $lightdark !important;
      }
      &:focus {
        background: $lightdark !important;
      }

      i {
        margin-right: 20px !important;
        font-size: 20px;
      }
    }
    .label {
        position: absolute;
        top: 5px;
        left: 35px;
        text-indent: 0;
        display: block !important;
        padding: .2em .6em .3em !important;
    }
    #sidebar-menu {
      ul > li {
        position: relative;
        white-space: nowrap;
        &:hover > a {
          position: relative;
          width: 280px;
          background: $lightdark;
          color: $custom;
          border-color: $custom;
        }
        &:hover > ul {
          display: block !important;
          left: 70px;
          position: absolute;
          // width: 190px;
          width: 210px;
          a {
            background: $white;
            box-shadow: none;
            padding-left: 15px;
            position: relative;
            width: 186px;
            z-index: 6;

            &:hover {
              color: $custom;
            }
          }
        }
        &:hover {
          a {
            span {
              display: inline;
            }
          }
        }
      }
      li{
        .show-menu + ul{
            display: block;
            left: 70px;
            position: absolute;
            width: 190px;
            a {
              background: $white;
              box-shadow: none;
              padding-left: 15px;
              position: relative;
              width: 186px;
              z-index: 6;

              &:hover {
                color: $custom;
              }
            }

        }
      }
      a.subdrop {
        color: $custom !important;
      }
      ul > li > ul {
        display: none !important;
      }
      ul {
        ul {
          li {
            &:hover > ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }
          li > a {
            span.pull-right {
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              position: absolute;
              right: 20px;
              top: 12px;
              transform: rotate(270deg);
            }
          }
          li.active {
            a{
              color: $custom;
            }
          }
        }
      }
      ul > li > a {
        span {
          display: none;
          padding-left: 10px;
        }
      }
    }
    .user-details {
      display: none;
    }
  }
  .content-page {
    margin-left: 70px;
  }
  .footer {
    left: 70px;
  }
  .topbar {
    .topbar-left {
      width: 70px !important;
      .logo {
        span {
          display: none;
          opacity: 0;
        }
        i {
          display: block;
          line-height: 70px;
          color: $dark !important;
        }
      }
    }

  }
  #sidebar-menu > ul > li {
    &:hover > a.open {
      :after {
        display: none;
      }
    }
    &:hover > a.active {
      :after {
        display: none;
      }
    }
  }

}

#wrapper.right-bar-enabled {
  .right-bar {
    right: 0;
  }
  .left-layout {
    left: 0;
  }
}

/* Right sidebar */
.side-bar.right-bar {
  float: right !important;
  right: -266px;
  top: 0px;
}

.side-bar {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  background-color: $white;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  display: block;
  float: left;
  height: $height;
  position: fixed;
  transition: all 200ms ease-out;
  width: 240px;
}

.right-bar {
  background: $white !important;
  z-index: 999 !important;
  h4 {
    border-bottom: 1px solid rgba($muted,0.5);
    padding: 4px 10px 10px 18px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $dark;
    letter-spacing: 0.03em;
  }
  .right-bar-toggle {
    float: right;
    line-height: 46px;
    font-size: 20px;
    color: #333;
    padding: 0 10px;
  }
}

.notification-list {
  height: 100%;

  .list-group-item {
    border-bottom: 1px solid rgba($muted,0.2) !important;
    margin-bottom: 0;
    background-color: $white;
  }
  .list-group-item.active {
    background-color: rgba($muted,0.1);

  }
}

.user-list {
  .user-list-item {

    .avatar {
      float: left;
      margin-right: 5px;
      width: 30px;
      height: 30px;

      img {
        border-radius: 50%;
        width: $width;
      }
    }

    .icon {
      float: left;
      margin-right: 5px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      text-align: center;

      i {
        color: $white;
        line-height: 30px;
        font-size: 16px;
      }
    }

    .user-desc {
      margin-left: 40px;

      span.name {
        color: $dark;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 100%;
        overflow: hidden;
      }


      span.desc {
        color: $muted;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 100%;
        overflow: hidden;
        font-size: 12px;
      }
      span.time {
        font-size: 11px;
        color: $custom;
        font-style: italic;
      }
    }
  }
}

// Card

.info-primary-card {
  border-radius: 12px;
  min-height: 48px;
  padding: 11px 28px;
  min-width: 256px;
  max-width: 448px;

  &.no-max-width {
    max-width: unset !important;
    padding: 16px 24px !important;
  }
}

// Button

.text-transparent-button {
  min-width: 60px;
  height: 36px;
  padding: 0px 16px;
  border-radius: 12px;
  border: none !important;
  cursor: pointer;
  font-size: 16px;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  color: $blue-primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.orange-gradient-btn{
  min-width: 60px;
  height: 36px;
  padding: 0px 16px;
  border-radius: 12px;
  border: none !important;
  cursor: pointer;
  font-size: 16px;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  color: $white;
  background: linear-gradient(276.52deg, #E54D0C -2.39%, #EF880E 107.31%);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

// Accordian

.accordian-card {
  background-color: transparent !important;

  &.collapsed {
    .fa-angle-up {
      transform: rotate(180deg);
      padding-bottom: 0px;
      margin-top: 9px !important;
    }
  }

  .lable-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 48px;
    cursor: pointer;
    align-items: center;
    .icon-i {
      margin: -1px 10px 5px 0px;

      .fa-angle-up {
        font-size: 26px;
        margin-top: 8px;
      }
    }
  }
}

/* Seach */
.app-search {
  position: relative;

  button {
    position: absolute;
    background-color: transparent;
    border: none;
    top: 4px;
    right: 8px;
    color: rgba($white, 0.7);

    &:hover {
      color: $white;
    }
  }

  .form-control,
  .form-control:focus {
    border: 1px solid rgba($white, 0.15);
    font-size: 13px;
    height: 34px;
    color: $white;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 40px;
    margin-top: 18px;
    margin-left: 20px;
    background: rgba($white, 0.1);
    box-shadow: none;
    border-radius: 30px;
    width: 190px;
  }
}

.accordian-card {
  background-color: transparent !important;

  &.collapsed {
    .fa-angle-up {
      transform: rotate(180deg);
      padding-bottom: 0px;
      margin-top: 9px !important;
    }
  }

  .lable-group {
    display: flex;
    flex-direction: row;
    height: 48px;
    cursor: pointer;
    align-items: center;

    .icon-i {
      color: #177CDA;
      margin: -1px 10px 5px 0px;

      .fa-angle-up {
        font-size: 26px;
        margin-top: 8px;
      }
    }
  }
}

.validate-text{
  font-size: 12px;
  color: #EF880E !important;
}

.ng-otp-input-wrapper{
  .otp-input{
    background-color: transparent;
  }
}
aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
  border: 2px solid #e6e6e6;
  color: #e6e6e6;
}
aw-wizard#kyc-checks-flow-wizard aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done:after, aw-wizard#checkout-form aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done:after {
  border: 2px solid #e6e6e6 !important;
}
//input.app-search-input {
//  &::-webkit-input-placeholder {
//    color: rgba($white, 0.7);
//    font-weight: normal;
//  }
//  &:-moz-placeholder {
//    color: fade($white, 70%);
//  }
//  &::-moz-placeholder {
//    color: fade($white, 70%);
//  }
//  &:-ms-input-placeholder {
//    color: fade($white, 70%);
//  }
//}
pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.aml-details-card {
  display: flex;
  flex-direction: column;

  span {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  .fa {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 2px 3px;
    margin-right: 10px;
  }

  .fa-times {
    padding: 2px 4px;
    color: #D84040 !important;
  }

  .fa-check {
    color: #00A510 !important;
  }
}
